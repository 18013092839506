<template>
  <v-app>

    <v-app-bar
        app
        color="#fcb69f"
        dark
        src="https://picsum.photos/1920/1080?random"
    >
      <template v-slot:img="{ props }">
        <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>


      <v-btn icon @click="$router.push('/')">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-app-bar-title>YI KANG Game Shop</v-app-bar-title>

      <v-spacer></v-spacer>


      <!-- login-->
      <v-btn icon v-on:click="login_dialog=true" v-if="!login_status">
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>

      <!-- register-->
      <v-btn icon v-on:click="register_dialog=true" v-if="!login_status">
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>

      <!-- logout -->
      <v-btn icon v-on:click="logout()" v-if="login_status">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>
    <div style="height: 80px"></div>

    <router-view/>

    <v-footer dark padless fixed>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          2022 - {{ new Date().getFullYear() }} — <strong>YI KANG XIN YING LIMITED</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <AlertBar ref="alertBar"/>

    <v-dialog v-model="register_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title><span class="text-h5">User Register</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field solo prepend-inner-icon="mdi-account" dense label="Your name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field solo prepend-inner-icon="mdi-email" dense label="Email*" v-model="req_body.email" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field solo prepend-inner-icon="mdi-lock" dense label="Password*" v-model="req_body.password" type="password" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field solo prepend-inner-icon="mdi-lock" dense label="Password Confirm*" v-model="req_body.password_confirm" type="password"
                              required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="register_dialog = false">
            Close
          </v-btn>
          <v-btn color="primary" :loading="register_loading" @click="register_user()">
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="login_dialog" persistent max-width="300px">
      <v-card>
        <v-card-title><span class="text-h5">User Register</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field  solo prepend-inner-icon="mdi-email" dense label="Email*" v-model="req_body.email" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field  solo prepend-inner-icon="mdi-lock" dense label="Password*" v-model="req_body.password" type="password"
                              required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login_dialog = false">
            Close
          </v-btn>
          <v-btn color="primary" :loading="login_loading" @click="login_user()">
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

import AlertBar from "@/components/AlertBar";
import Req from "@/func/request";

export default {
  components: {AlertBar},

  data: () => ({
    login_status: null,
    register_dialog: false,
    register_loading: false,
    login_dialog: false,
    login_loading: false,
    req_body: {merchant: Req.merchant_name, email: null, password: null, password_confirm: null},
  }),

  provide() {
    return {
      send_alert: this.send_alert
    }
  },

  created() {
    this.get_user_info()
  },

  methods: {
    send_alert(msg) {
      this.$refs.alertBar.open(msg)
    },
    get_user_info() {
      Req.doHTTPGet("/spv-api/v1/info",
          res => {
            if (res.code !== 0) {
              this.login_status = null
              return
            }
            this.login_status = res.data
          }, () => this.send_alert("Network Error"))
    },
    register_user() {
      if (!Req.is_email(this.req_body.email)) return this.send_alert("email format error")
      if (!this.req_body.password) return this.send_alert("password can not be empty")

      this.register_loading = true
      Req.doHTTPPost("/spv-api/v1/register", this.req_body,
          res => {
            this.register_loading = false
            if (res.code !== 0) return this.send_alert(res.message)
            this.send_alert("Register Success")
            this.req_body.password = null
            this.register_dialog = false
            this.login_dialog = true
          }, () => {
            this.register_loading = false
            this.send_alert("Network Error")
          })
    },
    login_user() {
      if (!Req.is_email(this.req_body.email)) return this.send_alert("email format error")
      if (!this.req_body.password) return this.send_alert("password can not be empty")

      this.login_loading = true
      Req.doHTTPPost("/spv-api/v1/login", this.req_body,
          res => {
            this.login_loading = false
            if (res.code !== 0) return this.send_alert(res.message)
            console.log(res.data.access_token)
            localStorage.setItem('token', res.data.access_token)
            this.send_alert("Login Success")
            this.login_dialog = false
            this.get_user_info()
          }, () => {
            this.login_loading = false
            this.send_alert("Network Error")
          })
    },
    logout() {
      localStorage.removeItem('token')
      this.login_status = null
      this.send_alert('Logout success')
    }
  },
}
</script>

<style lang="scss">

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
