// const url_prefix = 'http://localhost:9020'
const url_prefix = 'https://api-live.aiscpay.com'

export default {
  merchant_name: 'yikang',

  doHTTPPost: (url, body, onSuccess, onError) => {
    console.log("Req ===> " + url_prefix + url)
    console.log(body)

    return fetch(url_prefix +url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'token': localStorage.getItem('token')},
      body: JSON.stringify(body)
    }).then(res => res.json()).then(res => {
      console.log(res)
      if (res.code === 102) {
        localStorage.removeItem('token')
      }
      onSuccess(res)
    }).catch(err => {
      if (onError) onError(err)
      console.log("ReqErr ===> " + err)
    })
  },

  doHTTPGet: (url, onSuccess, onError) => {
    console.log("Req ===> " + url_prefix + url)

    return fetch(url_prefix +url, {
      method: 'GET',
      headers: {'token': localStorage.getItem('token')},
    }).then(res => res.json()).then(res => {
      console.log(res)
      if (res.code === 102) {
        localStorage.removeItem('token')
      }
      onSuccess(res)
    }).catch(err => {
      if (onError) onError(err)
      console.log("ReqErr ===> " + err)
    })
  },

  toHump: (name) => {
    let ret = name.replace(/_(\w)/g, (all, letter) => letter.toUpperCase());
    return ret.charAt(0).toUpperCase() + ret.slice(1)
  },

  toLine: (name) => {
    let x = name.replace(/([A-Z])/g, "_$1").toLowerCase();
    return x[0] === '_' ? x.substring(1) : x
  },
  dateFormat(date) {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    let hour = date.getHours()
    let minu = date.getMinutes()
    let sec = date.getSeconds()

    month = month >= 10 ? month : '0' + month
    day = day >= 10 ? day : '0' + day
    hour = hour >= 10 ? hour : '0' + hour
    minu = minu >= 10 ? minu : '0' + minu
    sec = sec >= 10 ? sec : '0' + sec

    return `${year}/${month}/${day} ${hour}:${minu}:${sec}`
  },

  is_email(inp) {
    return /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(inp.trim());
  },
};